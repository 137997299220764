<template>
  <div class="view pa24">
    <el-button
      type="primary"
      @click="
        centerDialogVisible = true;
        bEdit = false;
      "
      >添加优惠券</el-button
    >
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          width="50"
          type="index"
          align="center"
          label="序号"
          show-overflow-tooltip
        />
        <el-table-column prop="name" align="center" label="优惠卷名称" show-overflow-tooltip/>
        <el-table-column prop="goodsTypeName" align="center" label="使用范围" show-overflow-tooltip/>
        <el-table-column prop="grantModeText" align="center" label="发放方式" show-overflow-tooltip/>
        <el-table-column
          prop="grantObjectText"
          show-overflow-tooltip
          align="center"
          label="发放对象"
        />
        <el-table-column
          prop="receiveNum"
          show-overflow-tooltip
          align="center"
          label="领取数量（张）"
        >
          <template slot-scope="scope">
            <span style="color: #51cdcb">{{ scope.row.receiveNum }}张</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="couponTypeText"
          align="center"
          label="优惠卷类型"
        />
        <el-table-column prop="address" align="center" show-overflow-tooltip label="优惠卷折扣/金额">
          <template slot-scope="scope">
            <span style="color: #fd634e" v-if="scope.row.couponType == 1"
              >{{ scope.row.couponFaceValue }}元</span
            >
            <span style="color: #fd634e" v-if="scope.row.couponType == 2"
              >{{ scope.row.couponDiscount }}折</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="minAmount" align="center" show-overflow-tooltip label="最低消费金额">
          <template slot-scope="scope">
            <span>{{ scope.row.minAmount }}元</span>
          </template>
        </el-table-column>
        <el-table-column prop="couponNum" align="center" show-overflow-tooltip label="发券/剩余">
          <template slot-scope="scope">
            <span
              style="color:#51CBCD"
              >{{
                scope.row.couponNum === 0
                  ? "不限量"
                  : scope.row.couponNum + "/"
              }}</span>
              <span>{{ scope.row.couponNum - scope.row.receiveNum }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip label="发放日期" width="200">
          <template slot-scope="scope">
            <span
              >{{ scope.row.grantStartTime }}到{{
                scope.row.grantEndTime
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="有效日期" show-overflow-tooltip width="200">
          <template slot-scope="scope">
            <span
              >{{ scope.row.effectiveStartTime }}到{{
                scope.row.effectiveEndTime
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="pubState"
          align="center"
          label="发布状态"
          width="140"
        >
          <template slot-scope="scope">
            <el-switch
              @change="(e) => pubStateChange(e, scope.row)"
              :value="scope.row.pubState == 2 ? true : false"
              active-text="启用"
              inactive-text="禁用"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button class="mr10" type="text" @click="detail(scope.row.couponId)"
              >领取记录</el-button
            >
            <el-button class="mr10" type="text" @click="edit(scope.row)"
              >编辑</el-button
            >
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="del(scope.row.couponId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除这个优惠券吗？" >
              <el-button slot="reference" style="color: #fd634e" type="text"
                >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="添加优惠券"
      :visible.sync="centerDialogVisible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="40%"
    >
      <addCoupon
        :bEdit="bEdit"
        @submitForm="submitForm"
        @onConfirmEdit="onConfirmEdit"
        ref="addCoupon"
      />
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            centerDialogVisible = false;
            bEdit = false;
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { crmPcPageList, updState, add, del, upd } from "@/api/coupon";
import addCoupon from "./addCoupon";
export default {
  name: "couponList",
  components: {
    commonTable,
    addCoupon,
    customPopconfirm
  },
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      centerDialogVisible: false,
      bEdit: false, //编辑
      couponId: "", //编辑时需要传的id
      companyId: "", //编辑时需要传的id
    };
  },
  created() {
    this.crmPcPageList();
  },
  methods: {
    /**@method  优惠券列表*/
    async crmPcPageList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      };
      try {
        this.loading = true;
        const result = await crmPcPageList(data);
        this.loading = false;
        const { total, list } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    /**@method 发布状态 */
    async pubStateChange(e, { couponId }) {
      let data = {
        couponId,
        pubState: e ? 2 : 1,
      };
      await updState(data);
      await this.crmPcPageList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.crmPcPageList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.crmPcPageList();
    },
    determine() {
      this.$refs.addCoupon.submitForm();
    },
    /**
     * @method 发布优惠券
     * @param {Object} val - 表单值
     */
    async submitForm(val) {
      delete val.rangeType;
      let data = {
        ...val,
        goodstypeId: val.goodstypeId == "" ? 0 : val.goodstypeId,
        goodstypeIds: 0,
        pubState: val.pubState ? 2 : 1,
        grantStartTime: val.grantTime[0],
        grantEndTime: val.grantTime[1],
        effectiveStartTime: val.effectiveDate[0],
        effectiveEndTime: val.effectiveDate[1],
      };
      try {
        await add(data, { showLoading: true });
        this.centerDialogVisible = false;
        await this.crmPcPageList();
      } catch (error) {
        console.log(error);
         this.$message({
          message: error.message || "失败",
          type: "warning",
        });
      }
    },
    /**
     * @method 删除优惠券
     * @param {String} val - id
     */
    async del(val) {
      try {
        await del({ couponId: val });
        await this.crmPcPageList();
      } catch (error) {
        console.log(error);
        this.$message({
          message: error.message || "请选择需求",
          type: "warning",
        });
      }
    },
    /**
     * @method 领取记录
     * @param {String} val - 优惠券id
     */
    detail(id){
      this.$router.push('detailCoupon?id='+ id)
    },


    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      this.centerDialogVisible = true;
      this.bEdit = true;
      console.log(val);
      const {
        name,
        minAmount,
        couponType,
        couponFaceValue,
        rangeType,
        grantStartTime,
        grantEndTime,
        effectiveStartTime,
        effectiveEndTime,
        grantMode,
        couponNum,
        maxUser,
        pubState,
        couponId,
        companyId,
        goodstypeId,
        couponDiscount,
      } = val;
      this.couponId = couponId;
      this.companyId = companyId;
      let data = {
        name,
        minAmount,
        couponType: String(couponType),
        couponFaceValue,
        rangeType:
          goodstypeId && goodstypeId != 0 && goodstypeId != "" ? "2" : "1",
        grantTime: [grantStartTime, grantEndTime],
        effectiveDate: [effectiveStartTime, effectiveEndTime],
        grantMode: String(grantMode),
        couponNum,
        maxUser,
        pubState: pubState == 1 ? false : true,
        goodstypeId: val.goodstypeId ? val.goodstypeId : 0,
        couponDiscount,
      };
      this.$nextTick(() => {
        this.$refs.addCoupon.ruleForm = data;
      });
    },
    /**@method 确定编辑 */
    async onConfirmEdit(val) {
      delete val.rangeType;
      let data = {
        ...val,
        couponId: this.couponId,
        companyId: this.companyId,
        goodstypeId: val.goodstypeId ? val.goodstypeId : 0,
        goodstypeIds: 0,
        pubState: val.pubState ? 2 : 1,
        grantStartTime: val.grantTime[0],
        grantEndTime: val.grantTime[1],
        effectiveStartTime: val.effectiveDate[0],
        effectiveEndTime: val.effectiveDate[1],
      };
      console.log(data);
      try {
        await upd(data, { showLoading: true });
        this.centerDialogVisible = false;
        this.bEdit = false;
        await this.crmPcPageList();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>