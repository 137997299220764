<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="优惠券名称" prop="name">
        <el-input class="w220" v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="优惠劵最低消费" prop="minAmount">
        <el-input class="w220" v-model="ruleForm.minAmount"></el-input>
        元
      </el-form-item>
      <el-form-item label="优惠券类型" prop="couponType">
        <el-select v-model="ruleForm.couponType" placeholder="请选择">
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优惠券面值" prop="couponFaceValue" v-if="ruleForm.couponType == 1">
        <el-input
          placeholder="请输入优惠券面值"
          v-model="ruleForm.couponFaceValue"
          class="input-with-select"
          style='width:300px'
        >
          <template slot="prepend">满{{ruleForm.minAmount}}元减</template>
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="折扣" prop='couponDiscount' v-if="ruleForm.couponType == 2">
        <el-input-number
          placeholder="请输入折扣"
          v-model="ruleForm.couponDiscount"
          :min='1'
          :step='0.1'
          :max='9.99'
          class="input-with-select"
          style='width:220px'
        >
        </el-input-number>
        <span class="ml10">折</span>
      </el-form-item>
      <el-form-item label="使用范围" prop="rangeType">
        <el-radio
          class="mr10"
          style="margin: 0"
          v-model="ruleForm.rangeType"
          label="1"
          >全场通用</el-radio
        >
        <el-radio v-model="ruleForm.rangeType" label="2">按品类使用</el-radio>
        <el-select
          style="width: 150px"
          :disabled='ruleForm.rangeType == 1'
          v-model="ruleForm.goodstypeId"
          placeholder="选择商品分类"
        >
          <el-option
            v-for="(item, index) in categoriesArr"
            :key="index"
            :label="item.name"
            :value="item.goodstypeId"
          >
          </el-option>
        </el-select>
        专用
      </el-form-item>
      <el-form-item label="发劵日期" prop="grantTime">
        <el-date-picker
          v-model="ruleForm.grantTime"
          class="mr10"
          align="right"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        />
      </el-form-item>
      <el-form-item label="使用有效日期" prop="effectiveDate">
        <el-date-picker
          v-model="ruleForm.effectiveDate"
          class="mr10"
          align="right"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        />
      </el-form-item>
      <el-form-item label="发放方式" prop="grantMode">
        <el-radio v-model="ruleForm.grantMode" label="0"
          >新用户赠劵</el-radio
        >
        <el-radio v-model="ruleForm.grantMode" label="1">首页赠劵</el-radio>
      </el-form-item>
      <el-form-item label="发放数量" prop="couponNum">
        <el-input
          class="w220"
          type="number"
          placeholder="请输入发放数量"
          v-model="ruleForm.couponNum"
        />
        张
      </el-form-item>
      <el-form-item label="同一用户最多发放" prop="maxUser">
        <el-input
          class="w220"
          type="number"
          placeholder="请输入"
          v-model="ruleForm.maxUser"
        />
        张
      </el-form-item>
      <el-form-item label="发放状态" prop="pubState">
        <el-switch
          v-model="ruleForm.pubState"
          active-text="启用"
          inactive-text="禁用"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="备注" prop="note">
        <el-input
          type="textarea"
          class="w220"
          autosize
          placeholder="备注内容"
          v-model="ruleForm.note"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getGoodsTypeInfo } from '@/api/coupon';
export default {
  name: "addCoupon",
  data() {
    let scopeRules = (rule, value, callback) => {
      if(this.ruleForm.rangeType == 2 && this.ruleForm.goodstypeId == ''){
        callback(new Error('请选择商品分类'));
      }else {
        callback();
      }
    }
    return {
      options: [
        {
          label: "满减券",
          value: "1",
        },
        {
          label: "折扣券",
          value: "2",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      categoriesArr: [],
      ruleForm: {
        name: "",
        minAmount: "",
        couponFaceValue: "",
        couponDiscount:'',
        rangeType: "1",
        couponType: "",
        goodstypeId: '',
        grantTime: "",
        effectiveDate: "",
        grantMode: "0",
        couponNum: "",
        maxUser: "",
        pubState: false,
        note: "",
      },
      rules: {
        name:[
          { required: true, message: "请输入优惠券名称" }
        ],
        minAmount:[
          { required: true, message: "请输入优惠劵最低消费金额" }
        ],
        couponType:[
          { required: true, message: "请选择优惠券类型" }
        ],
        couponFaceValue:[
          { required: true, message: "请输入优惠券面值" }
        ],
        rangeType:[
          { validator: scopeRules, required: true }
        ],
        grantTime:[
          { required: true, message: "请选择发劵日期" }
        ],
        effectiveDate:[
          { required: true, message: "请选择使用有效日期" }
        ],
        grantMode:[
          { required: true, message: "请选择发放方式" }
        ],
        couponNum:[
          { required: true, message: "请输入发放数量" }
        ],
        maxUser:[
          { required: true, message: "请输入最多发放数量" }
        ],
        pubState:[
          { required: true, message: "请选择发放状态" }
        ],
        couponDiscount:[
          { required: true, message: "请输入折扣" }
        ],
      }
    };
  },
  props:{
    bEdit:{
      type:Boolean,
      default:false
    }
  },
  created(){
    this.getGoodsTypeInfo();
  },
  methods:{
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.bEdit){
            this.$emit("onConfirmEdit", this.ruleForm);
          }else{
            this.$emit("submitForm", this.ruleForm);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  async getGoodsTypeInfo(){
       const result = await getGoodsTypeInfo({ignore:true});
       this.categoriesArr = result.data;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
